import { useApi } from '@/utils/api'
import EventBus from '@/utils/event-bus'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { getCookie, setCookie, deleteCookie } from '@/utils/cookies'

const hostname = window.location.hostname || window.location.host
const COOKIE_DOMAIN = hostname.includes('wasabil.com') ? '.wasabil.com' : hostname
const COOKIE_DAYS = 30
const COOKIE_KEY = 'wsbtoken'

const useAuthStore = create(
	subscribeWithSelector((set, get) => ({
		token: window.localStorage.getItem('token') || getCookie(COOKIE_KEY) || null,
		setToken: (token) => {
			set({ token })
			window.localStorage.setItem('token', token)
			setCookie(COOKIE_KEY, token, COOKIE_DAYS, COOKIE_DOMAIN)
		},
		user: null,
		setUser: (user) => set({ user }),
		initializeUser: async () => {
			let token = get().token
			if (!token) return
			const { data: user } = await get().reloadUser()
			if (!user) return null
			await get().login(token, user)
			return user
		},
		reloadUser: (opts = {}) => {
			return useApi({
				...opts,
				url: '/auth/me',
				success: ({ data }) => {
					set({ user: data })
				},
			})
		},
		login: async (token, user) => {
			set({ token, user })
			window.localStorage.setItem('token', token)
			setCookie(COOKIE_KEY, token, COOKIE_DAYS, COOKIE_DOMAIN)
			await EventBus.emit('login', { token, user })
		},
		logout: async (doRedirect = true) => {
			window.localStorage.removeItem('token')
			deleteCookie(COOKIE_KEY, COOKIE_DOMAIN)
			await EventBus.emit('logout')
			if (doRedirect) window.location.href = '/login'
		},
		isLoggedIn: () => !!get().token,
		locale: 'es',
		setLocale: (locale) => set({ locale }),
	}))
)

export default useAuthStore

