import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

const useRouteStore = create(
	subscribeWithSelector((set, get) => ({
		route: null,
		setRoute: (route) => set({ route }),
	}))
)

export default useRouteStore

