import useAuthStore from '@/stores/auth-store'
import { lazy } from 'react'

const enterPanelIfLoggedIn = () => {
	const isLoggedIn = useAuthStore.getState().isLoggedIn()
	if (isLoggedIn) return '/dashboard'
}

const moved = (path) => () => `${process.env.REACT_APP_PUBLIC_URL}/${path}`

export default [
	{
		path: '/login',
		component: lazy(() => import('@/views/authentication/Login')),
		layout: 'BlankLayout',
		beforeRoute: [enterPanelIfLoggedIn],
	},
	{
		path: '/register',
		component: lazy(() => import('@/views/authentication/Register')),
		layout: 'BlankLayout',
		beforeRoute: [enterPanelIfLoggedIn],
	},
	{
		path: '/forgot-password',
		component: lazy(() => import('@/views/authentication/ForgotPassword')),
		layout: 'BlankLayout',
		beforeRoute: [enterPanelIfLoggedIn],
	},
	{
		path: '/reset/password/:token',
		component: lazy(() => import('@/views/authentication/ResetPassword')),
		layout: 'BlankLayout',
		beforeRoute: [enterPanelIfLoggedIn],
	},
	{
		path: '/connect/:key',
		component: lazy(() => import('@/views/ConnectView')),
		layout: 'BlankLayout',
	},
	{
		path: '/terminos-y-condiciones',
		// component: lazy(() => import('@/views/TermsAndConditions')),
		layout: 'BlankLayout',
		beforeRoute: [moved('/terminos-y-condiciones')],
	},
	{
		path: '/politicas-de-privacidad',
		// component: lazy(() => import('@/views/PrivacyPolicy')),
		layout: 'BlankLayout',
		beforeRoute: [moved('/politicas-de-privacidad')],
	},
	{
		path: '/contacto',
		// component: lazy(() => import('@/views/ContactForm')),
		layout: 'BlankLayout',
		beforeRoute: [moved('/contacto')],
	},
	{
		path: '/',
		exact: true,
		// component: lazy(() => import('@/views/Home')),
		layout: 'BlankLayout',
		beforeRoute: [enterPanelIfLoggedIn, () => `/login`],
	},
]

