import { FormattedMessage } from 'react-intl'

export default function Footer() {
	return (
		<footer className="px-1 py-25" style={{ backgroundColor: '#0000000c', lineHeight: '1' }}>
			COPYRIGHT © {new Date().getFullYear()}{' '}
			<a href="https://gux.tech" target="_blank" rel="noopener noreferrer">
				GUX
			</a>
			. Todos los derechos reservados.
		</footer>
	)
}

