import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

function getCurrentSize() {
	const points = {
		xs: 600,
		sm: 960,
		md: 1264,
		lg: 1904,
		xl: 99999,
	}

	const width = window.innerWidth
	for (let key in points) {
		if (width < points[key]) return key
	}
}

function getBreakpointObj(size) {
	const b = {
		name: size,
		xs: size === 'xs',
		sm: size === 'sm',
		smAndDown: ['xs', 'sm'].includes(size),
		smAndUp: ['sm', 'md', 'lg', 'xl'].includes(size),
		md: size === 'md',
		mdAndDown: ['xs', 'sm', 'md'].includes(size),
		mdAndUp: ['md', 'lg', 'xl'].includes(size),
		lg: size === 'lg',
		lgAndDown: ['xs', 'sm', 'md', 'lg'].includes(size),
		lgAndUp: ['lg', 'xl'].includes(size),
		xl: size === 'xl',
	}

	b.mobile = b.xs
	b.mobiletablet = b.smAndDown
	b.tablet = b.sm
	b.tabletdesktop = b.smAndUp
	b.desktop = b.mdAndUp

	return b
}

let currentSize = getCurrentSize()
let initialObj = getBreakpointObj(currentSize)

const useDeviceStore = create(
	subscribeWithSelector((set, get) => ({
		breakpoint: { ...initialObj },
		setBreakpoint: (obj) => set({ breakpoint: obj }),
	}))
)

// window.addEventListener('resize', () => {
// 	let size = getCurrentSize()
// 	if (size == currentSize) return
// 	useDeviceStore.getState().setBreakpoint(getBreakpointObj(size))
// })

export default useDeviceStore

