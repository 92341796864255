import React from 'react'
import './companies-form-drawer.scss'
import WDrawer from '@/components/WDrawer'
import CompaniesForm from '@/components/CompaniesForm'
import { Edit, Plus } from 'react-feather'

export default function CompaniesFormDrawer({ uuid, open, setOpen, onSave, viewOnly }) {
	return (
		<div className="companies-form-drawer">
			<WDrawer
				width={900}
				open={open}
				setOpen={setOpen}
				title={uuid ? 'Editar Empresa' : 'Nueva Empresa'}
				Icon={uuid ? Edit : Plus}
			>
				<CompaniesForm
					uuid={uuid}
					onSave={(company, isNew) => {
						onSave?.(company, isNew)
						setOpen(false)
					}}
					viewOnly={viewOnly}
				/>
			</WDrawer>
		</div>
	)
}

