import { CODE_DOCUMENT_STATUS_DRAFT } from '@/environment'
import useEntitiesStore from '@/stores/entities-store'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
	return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
}

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
	const today = new Date()
	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	)
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
	if (!value) return value
	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value)
	let formatting = { month: 'short', day: 'numeric' }

	if (toTimeForCurrentDay && isToday(date)) {
		formatting = { hour: 'numeric', minute: 'numeric' }
	}

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary25: '#7367f01a', // for option hover bg-color
		primary: '#7367f0', // for selected option bg-color
		neutral10: '#7367f0', // for tags bg-color
		neutral20: '#ededed', // for input border-color
		neutral30: '#ededed', // for input hover border-color
	},
})

export const getDataByLanguage = ($key, text) => {
	try {
		return text[$key]
	} catch (error) {
		return ''
	}
}

export const getItemsByRol = (roles, role_id) => {
	const items = []

	roles.map((role) => {
		if (role.id === role_id) {
			items.push(role.menus)
		}
	})

	return items[0]
}

export const getFormatYYYYMMDD = (date) => {
	const fecha = new Date(date)

	const result =
		String(fecha.getFullYear()) +
		String('-') +
		String(String('0') + String(Number(fecha.getMonth()) + 1)).slice(-2) +
		String('-') +
		String(String('0') + String(fecha.getDate())).slice(-2)

	return result
}

export const getYear = (date) => {
	const fecha = new Date(date)

	return parseInt(fecha.getFullYear())
}

export const getMonth = (date) => {
	const fecha = new Date(date)

	return parseInt(fecha.getMonth())
}

export const getDay = (date) => {
	const fecha = new Date(date)
	return parseInt(fecha.getDate())
}

export const redondearIVA = (price) => {
	// Redondeo al centavo más cercano
	const redondeoIVA = Math.ceil(0.19 * price * 100) / 100
	// console.log(redondeoIVA.toFixed(0));
	return redondeoIVA.toFixed(0)
}

export const symbolOfCurrency = (currency) => {
	if (!isNaN(currency)) {
		return useEntitiesStore.getState().currencies.find((c) => c.id == currency).symbol
	} else if (currency && typeof currency === 'object') {
		return currency.symbol
	} else {
		return currency
	}
}

export const formatNumber = (amount, decimals, symbol = null) => {
	let amountHasDecimals = Number(amount).toString().includes('.')
	let num = new Intl.NumberFormat('es-CL', {
		minimumFractionDigits: amountHasDecimals ? decimals : 0,
		maximumFractionDigits: amountHasDecimals ? decimals : 0,
	}).format(amount)

	if (!symbol) return num
	else return `${symbol} ${num}`
}

export const decimalByCurrency = (currency) => {
	return symbolOfCurrency(currency) == 'CLP' ? 0 : 2
}

export const formatByCurrency = (amount, currency, symbol = null) => {
	let decimals = decimalByCurrency(currency)
	if (symbol === false) return formatNumber(amount, decimals)
	else return formatNumber(amount, decimals, symbol || symbolOfCurrency(currency))
}

export const roundByCurrency = (amount, currencySymbol) => {
	let decimals = decimalByCurrency(currencySymbol)
	// eslint-disable-next-line prefer-template
	return Number(Math.round(amount + 'e' + decimals) + 'e-' + decimals)
}

export const canEditDocument = (status_id) => {
	return status_id === CODE_DOCUMENT_STATUS_DRAFT
}

export const getFirstName = (fullName) => {
	try {
		return fullName.split(' ')[0]
	} catch (error) {
		return ''
	}
}

export const getRolePriority = (roles) => {
	let has_superadmin_empresa = false

	if (roles.length === 1) return roles[0]

	roles.map((role) => {
		if (parseInt(role.id) === 2) {
			has_superadmin_empresa = true
		}
	})

	if (has_superadmin_empresa) {
		return roles.find((rol) => rol.id === 2)
	}

	return roles[0]
}

export const meses = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
]

export function formatRut(rut, fallback = null) {
	rut = rut.replace(/[.-\s]/g, '')
	const lastChar = rut.slice(-1)
	rut = rut.slice(0, -1)

	const rutArray = rut.split('').reverse()
	for (let i = 3; i < rutArray.length; i += 4) {
		rutArray.splice(i, 0, '.')
	}
	rut = rutArray.reverse().join('')

	rut = `${rut}-${lastChar}`

	const pattern = /^[0-9]{1,2}\.[0-9]{3}\.[0-9]{3}-[0-9]{1}$/
	if (!pattern.test(rut)) {
		return fallback
	}

	return rut
}

export function preloadImage(src) {
	src = [src].flat()
	return Promise.all(
		src.map((src) => {
			return new Promise((resolve) => {
				const img = new Image()
				img.onload = resolve
				img.onerror = resolve
				img.src = src
			})
		})
	)
}

