import React, { useEffect, useMemo, useRef, useState } from 'react'
import useCompaniesStore from '@/stores/companies-store'
import './style.scss'
import { ChevronDown, ChevronRight, ChevronUp, Plus } from 'react-feather'
import classNames from 'classnames'
import CompaniesFormDrawer from '@/components/CompaniesFormDrawer'
import useDeviceStore from '@/stores/device-store'

const getFirstTwoLetters = (name) => {
	return name
		.replace(/[^a-zA-Z ]/g, '')
		.slice(0, 2)
		.toUpperCase()
}

export default function ActiveCompanySelector() {
	const breakpoint = useDeviceStore((state) => state.breakpoint)
	const [open, setOpen] = useState(false)
	const [companiesFormDrawerOpen, setCompaniesFormDrawerOpen] = useState(false)
	const menuRef = useRef()

	const [activeCompany, companies, switchActiveCompany] = useCompaniesStore((state) => [
		state.activeCompany,
		state.companies,
		state.switchActiveCompany,
	])

	const companiesOptions = useMemo(() => {
		return (
			companies
				// .filter((company) => company.uid !== activeCompany?.uid)
				.sort((a, b) => a.name.localeCompare(b.name))
		)
	}, [companies, activeCompany])

	const createNewCompanyHandler = () => {
		setCompaniesFormDrawerOpen(true)
		setOpen(false)
	}

	const iSwitchActiveCompany = (companyUid) => {
		setOpen(false)
		if (companyUid == activeCompany.uid) return
		switchActiveCompany(companyUid)
	}

	useEffect(() => {
		// click outside handler
		function handleClickOutside(event) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpen(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [menuRef])

	if (!activeCompany) return null

	return (
		<>
			<div className="active-company-selector" ref={menuRef}>
				<div className="active-company-selector__activator-btn" onClick={() => setOpen(!open)}>
					<div className="active-company-selector__logo bg-primary">
						{getFirstTwoLetters(activeCompany.name)}
					</div>
					<div className="active-company-selector__name-cont">
						<div className="active-company-selector__name">{activeCompany.name}</div>
						<small className="active-company-selector__rut d-block">{activeCompany.rut}</small>
					</div>
					<div className="spacer"></div>
					{breakpoint.mobile && open ? (
						<ChevronUp size={30} className="ml-50" color="#777" />
					) : breakpoint.mobile && !open ? (
						<ChevronDown size={30} className="ml-50" color="#777" />
					) : (
						<ChevronRight size={24} className="ml-50" color="#ccc" />
					)}
				</div>
				<div
					className={classNames('active-company-selector__menu-cont', {
						'active-company-selector__menu-cont--opened': open,
					})}
				>
					<div className="active-company-selector__menu-overlay" onClick={() => setOpen(false)}></div>
					<div className="active-company-selector__menu-box">
						<div className="active-company-selector__menu">
							{companiesOptions.map((company) => {
								return (
									<div
										className={classNames('active-company-selector__opt', {
											'active-company-selector__opt--active': activeCompany.uid === company.uid,
										})}
										key={company.uid}
										onClick={() => iSwitchActiveCompany(company.uid)}
									>
										<div className="active-company-selector__logo bg-primary">
											{getFirstTwoLetters(company.name)}
										</div>
										<div className="active-company-selector__name-cont">
											<div className="active-company-selector__name">{company.name}</div>
											<small className="active-company-selector__rut d-block">{company.rut}</small>
										</div>
									</div>
								)
							})}
							<div className="active-company-selector__opt" onClick={createNewCompanyHandler}>
								<Plus size={28} color="var(--success)" />
								<span>Agregar empresa</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CompaniesFormDrawer
				open={companiesFormDrawerOpen}
				setOpen={setCompaniesFormDrawerOpen}
				onSave={(company) => iSwitchActiveCompany(company.uid)}
			/>
		</>
	)
}

