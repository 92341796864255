import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from '@/utils/context/ThemeColors'
import { IntlProviderWrapper } from '@/utils/context/Internationalization'

import '@/styles/index.scss'
import '@/stores/auth-store'
import '@/stores/permissions-store'
import '@/stores/companies-store'
import '@/stores/notifications-store'
import '@/stores/event-listeners'

import App from './App'

ReactDOM.render(
	<ThemeContext>
		<IntlProviderWrapper>
			<App />
			<ToastContainer newestOnTop />
		</IntlProviderWrapper>
	</ThemeContext>,
	document.getElementById('root')
)

// ** Service Worker
if (process.env.NODE_ENV === 'production') {
	import('./serviceWorker').then((serviceWorker) => {
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		serviceWorker.unregister()
	})
}

