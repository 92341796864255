import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import usePermissionsStore, { Permissions } from '@/stores/permissions-store'
import { lazy } from 'react'
import {
	Activity,
	Box,
	Briefcase,
	Calendar,
	FileText,
	List,
	Plus,
	Settings,
	Share2,
	Star,
	User,
	X,
} from 'react-feather'

const shouldBeLoggedIn = () => {
	const isLoggedIn = useAuthStore.getState().isLoggedIn()
	if (!isLoggedIn) {
		window.sessionStorage.setItem('login_redirect', window.location.pathname)
		return `/login`
	}
}

const shouldHaveActiveCompany = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (!activeCompany) return '/companies'
}

const userShould = (permission) => () => usePermissionsStore.getState().userShould(permission)

const shouldSaleStatusBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_sale_status == 'disabled') return '/companies'
}

const shouldPurchaseStatusBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_purchase_status == 'disabled') return '/companies'
}

export default [
	{
		path: '/dashboard',
		component: lazy(() => import('@/views/DashboardView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Inicio',
		icon: Activity,
	},
	{
		path: '/documents',
		component: lazy(() => import('@/views/DocumentsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, shouldPurchaseStatusBeNotDisabled],
		title: 'Facturas de Compra',
		icon: FileText,
	},
	{
		path: '/sale-invoice/documents',
		component: lazy(() => import('@/views/SaleDocumentsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, shouldSaleStatusBeNotDisabled],
		title: 'Facturas de Venta',
		icon: FileText,
	},
	{
		path: '/issue',
		component: lazy(() => import('@/views/IssueDocumentsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseStatusBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
		title: 'Emitir Facturas de Compra',
		icon: Plus,
	},
	{
		path: '/sale-invoice/issue',
		component: lazy(() => import('@/views/IssueSaleDocumentsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleStatusBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
		title: 'Emitir Facturas de Venta',
		icon: Plus,
	},
	{
		path: '/scheduleds',
		component: lazy(() => import('@/views/ScheduledsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseStatusBeNotDisabled,
			userShould(Permissions.EDIT_PROGRAMMED_DOCUMENTS),
		],
		title: 'Programar Facturas de Compra',
		icon: Calendar,
	},
	{
		path: '/sale-invoice/scheduleds',
		component: lazy(() => import('@/views/SaleScheduledsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleStatusBeNotDisabled,
			userShould(Permissions.EDIT_PROGRAMMED_DOCUMENTS),
		],
		title: 'Programar Facturas de Venta',
		icon: Calendar,
	},
	{
		path: '/integrations',
		component: lazy(() => import('@/views/IntegrationsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseStatusBeNotDisabled,
			userShould(Permissions.EDIT_INTEGRATIONS),
		],
		title: 'Integraciones',
		icon: Share2,
	},
	{
		path: '/suppliers',
		component: lazy(() => import('@/views/SuppliersView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Listado de Proveedores',
		icon: List,
	},
	{
		path: '/clients',
		component: lazy(() => import('@/views/ClientsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Listado de Clientes',
		icon: List,
	},
	{
		path: '/account',
		component: lazy(() => import('@/views/account-settings')),
		beforeRoute: [shouldBeLoggedIn],
		title: 'Mi Cuenta',
		icon: User,
	},
	{
		path: '/companies',
		component: lazy(() => import('@/views/CompaniesView')),
		beforeRoute: [shouldBeLoggedIn],
		title: 'Mi Empresa',
		icon: Briefcase,
	},
	{
		path: '/referral-program',
		component: lazy(() => import('@/views/ReferralProgramView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
		title: 'Programa de Referidos',
		icon: Star,
	},
	{
		path: '/companies/issuer',
		component: lazy(() => import('@/views/CompaniesIssuersView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, userShould(Permissions.COMPANY_ADMIN)],
		title: 'Configuración de Facturador',
		icon: Settings,
	},
	{
		path: '/sign/:companyUid/:hash',
		component: lazy(() => import('@/views/MobileSignView')),
		layout: 'BlankLayout',
	},
	{
		path: '/plan',
		component: lazy(() => import('@/views/PlanView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, userShould(Permissions.COMPANY_ADMIN)],
		title: 'Plan de Suscripción',
		icon: Box,
	},
]

