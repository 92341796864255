import { useState, useEffect, Suspense, lazy } from 'react'
import classnames from 'classnames'
import './style.scss'

import Footer from './Footer'
import Header from './Header'
import AppSidebar from './AppSidebar'
import NotificationsBar from './NotificationsBar'
import WasabilitoSpinner from '@/components/WasabilitoSpinner'

export default function PanelLayout({ route }) {
	const [notifShown, setNotifShown] = useState(false)

	useEffect(() => {
		document.getElementsByTagName('html')[0].classList.add('html-panel-layout')
		return () => document.getElementsByTagName('html')[0].classList.remove('html-panel-layout')
	}, [])

	return (
		<div className={classnames('panel-layout', { '--notif-shown': notifShown })}>
			<NotificationsBar onShow={() => setNotifShown(true)} onHide={() => setNotifShown(false)} />
			<AppSidebar />

			<div className="__content-block">
				<Header />
				<div className="__view">
					<Suspense fallback={<WasabilitoSpinner />}>
						<route.component />
					</Suspense>
				</div>
				<Footer />
			</div>
		</div>
	)
}

