import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import reactDom from 'react-dom'
import { X } from 'react-feather'
import './style.scss'
import useDeviceStore from '@/stores/device-store'

let count = 0
const State = {
	CLOSED: 1,
	OPENING: 2,
	OPEN: 3,
	CLOSING: 4,
}

export default function WDrawer({
	children,
	open,
	setOpen,
	title,
	header = null,
	className,
	width = '600px',
	maxWidth = '90%',
	cardStyle,
	Icon,
}) {
	const breakpoint = useDeviceStore((state) => state.breakpoint)
	const [rootElm, setRootElm] = useState(null)
	const [zIndex, setZIndex] = useState(0)
	const [internalState, setInternalState] = useState(open ? State.OPEN : State.CLOSED)
	const [firstOpen, setFirstOpen] = useState(false)

	const cCardStyle = useMemo(() => {
		let cWidth = typeof width == 'number' ? `${width}px` : width
		return {
			width: cWidth,
			maxWidth,
			...(cardStyle || {}),
		}
	})

	useEffect(() => {
		let elm = document.createElement('div')
		elm.style.position = 'relative'
		elm.style.zIndex = '9100'
		document.getElementById('root').appendChild(elm)
		setRootElm(elm)
		return () => document.getElementById('root').removeChild(elm)
	}, [])

	useEffect(() => {
		if (open) {
			setFirstOpen(true)
			count++
			setZIndex(count)
			document.body.classList.add('w-drawer-open')
			setInternalState(State.OPENING)
			setTimeout(() => {
				setInternalState(State.OPEN)
			}, 50)
		} else if (firstOpen) {
			count--
			if (count === 0) {
				document.body.classList.remove('w-drawer-open')
			}
			setInternalState(State.CLOSING)
			setTimeout(() => {
				setInternalState(State.CLOSED)
			}, 300)
		}
	}, [open])

	if (!rootElm || internalState == State.CLOSED) return null

	return reactDom.createPortal(
		<div
			className={classNames('w-drawer', className, { '--open': internalState == State.OPEN })}
			style={{ zIndex }}
		>
			<div className="__overlay" onClick={() => setOpen(false)}></div>
			<div className="__card" style={cCardStyle}>
				{header === false ? null : header === null ? (
					<div className="__header">
						<div className="__title">
							{Icon && <Icon className="__title-icon" size={breakpoint.mobile ? 14 : 16} />}
							<div className="__title-text">{title}</div>
						</div>
						<X className="__close-btn" onClick={() => setOpen(false)} />
					</div>
				) : (
					header
				)}
				<div className="__content">{children}</div>
			</div>
		</div>,
		rootElm
	)
}

