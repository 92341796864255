import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

const useLayoutStore = create(
	subscribeWithSelector((set, get) => ({
		sidebarOpen: false,
		setSidebarOpen: (open) => set({ sidebarOpen: open }),
	}))
)

export default useLayoutStore

