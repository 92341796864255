import useRouteStore from '@/stores/route-store'
import { Menu } from 'react-feather'
import logoWasabilImg from '../logo-wasabil.webp'
import './style.scss'
import useLayoutStore from '@/stores/layout-store'
import { NavLink } from 'react-router-dom'

export default function Header() {
	const route = useRouteStore((state) => state.route)
	const setSidebarOpen = useLayoutStore((state) => state.setSidebarOpen)
	return (
		<>
			<header className="header">
				<div className="__menu-logo">
					<Menu size={32} onClick={() => setSidebarOpen(true)} />
					<NavLink to="/dashboard" className="__logo">
						<img src={logoWasabilImg} alt="Wasabil" />
					</NavLink>
				</div>
				<div className="__view-title">
					<div className="__view-title-icon-container">{!!route?.icon && <route.icon />}</div>
					{!!route?.title && <h2>{route.title}</h2>}
				</div>
			</header>
			<div className="header-blank-space"></div>
		</>
	)
}

