import { useApi } from '@/utils/api'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import useAuthStore from './auth-store'
import useCompaniesStore from './companies-store'

export const Permissions = Object.freeze({
	COMPANY_ADMIN: 'company_admin',
	EDIT_INTEGRATIONS: 'edit_integrations',
	EDIT_PROGRAMMED_DOCUMENTS: 'edit_programmed_documents',
	ISSUE_DOCUMENTS: 'issue_documents',
	READ: 'read',
})

export function onForbidden() {
	window.location.href = '/companies?forbidden=true'
}

const usePermissionsStore = create(
	subscribeWithSelector((set, get) => ({
		permissions: null,
		loadPermissions: (opts = {}) => {
			return useApi({
				...opts,
				url: '/permissions',
				success: ({ data }) => {
					set({ permissions: data })
				},
			})
		},
		activePermissions: null,
		setActivePermissions: (permissions) => {
			set({ activePermissions: permissions })
		},
		getAllIncludedPermissionsOf(grantedPermissions) {
			let allPermissions = get().permissions || []
			let includedPermissions = []
			for (let permission of allPermissions) {
				if (grantedPermissions.includes(permission.key)) {
					includedPermissions = [...includedPermissions, ...(permission.includes || [])]
				}
			}
			return Array.from(new Set([...grantedPermissions, ...includedPermissions]))
		},
		userCan(permission) {
			let { activePermissions } = get()
			return get()
				.getAllIncludedPermissionsOf(activePermissions || [])
				.includes(permission)
		},
		userShould(permission) {
			if (!get().userCan(permission)) {
				onForbidden()
			}
		},
		getPermissionInfo(key) {
			return get().permissions?.find((x) => x.key == key)
		},
	}))
)

export default usePermissionsStore

// watch app store activeCompany
useCompaniesStore.subscribe(
	(state) => state.activeCompany,
	(activeCompany) => {
		if (activeCompany) {
			const user = useAuthStore.getState().user
			const companyUser = activeCompany.company_users.find((x) => x.user_id == user.id)
			usePermissionsStore.getState().setActivePermissions(companyUser.permissions)
		} else {
			usePermissionsStore.getState().setActivePermissions(null)
		}
	}
)

