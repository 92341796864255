import logo from '@/assets/images/logo/wasabil_60x60.webp'
import './style.scss'

export default function WasabilitoSpinner() {
	return (
		<div className="wasabilito-spinner">
			<img className="__logo" src={logo} alt="logo" />
			<div className="__loading loading">
				<div className="effect-1 effects"></div>
				<div className="effect-2 effects"></div>
				<div className="effect-3 effects"></div>
			</div>
		</div>
	)
}

