import React, { lazy, Suspense } from 'react'
import './style.scss'
import WasabilitoSpinner from '@/components/WasabilitoSpinner'

export default function BlankLayout({ route }) {
	let Comp = lazy(() => new Promise((r) => {}))

	return (
		<div className="blank-layout">
			<Suspense fallback={<WasabilitoSpinner />}>
				<route.component />
				{/* <Comp /> */}
			</Suspense>
		</div>
	)
}

