import React, { useEffect, useMemo, useState } from 'react'
import './style.scss'
import { useNotificationsStore } from '@/stores/notifications-store'
import { Button } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import { X } from 'react-feather'
import classNames from 'classnames'

export default function NotificationsBar({ onShow, onHide }) {
	const location = useLocation()
	const currentNotif = useNotificationsStore((s) => s.current)
	const dismissNotification = useNotificationsStore((s) => s.dismissNotification)
	const [notif, setNotif] = useState(currentNotif)
	useEffect(() => {
		if (currentNotif) setNotif(currentNotif)
	}, [currentNotif])

	const showNotif = useMemo(() => {
		return !!currentNotif && location.pathname !== currentNotif.ctaLink
	}, [currentNotif, location.pathname])

	useEffect(() => {
		if (showNotif) onShow?.()
		else onHide?.()
	}, [showNotif])

	return (
		<div className={classNames('notifications-bar')}>
			{notif && (
				<div className={classNames(`notification d-flex align-items-center bg-${notif.style}`)}>
					<div className="notification-message">{notif.message}</div>
					<div className="spacer"></div>
					<Link
						to={notif.ctaLink}
						onClick={() => dismissNotification(notif)}
						className="notification-cta"
					>
						<Button color="light" size="sm" outline>
							{notif.ctaText}
						</Button>
					</Link>
					{notif.dismissable && (
						<Button
							color={notif.style}
							size="sm"
							className="nowrap ml-50 ml-sm-1"
							onClick={() => dismissNotification(notif)}
						>
							<X size={14} />
						</Button>
					)}
				</div>
			)}
		</div>
	)
}

