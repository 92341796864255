// ** React Imports
import { Suspense, useEffect, useState } from 'react'

// ** Utils
import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'
import { routes } from './routes'
import { createApi } from '@/utils/api'

import { toast } from 'react-toastify'

import BlankLayout from '@/layouts/BlankLayout'
import PanelLayout from '@/layouts/PanelLayout'
import ScrollManager from '@/components/ScrollManager'
import useAuthStore from '@/stores/auth-store'
import PageNotFoundView from '@/views/misc/Error'
import { onForbidden } from '@/stores/permissions-store'
import useRouteStore from '@/stores/route-store'
import WasabilitoSpinner from '@/components/WasabilitoSpinner'

const api = createApi({ baseUrl: process.env.REACT_APP_API_URL })

const layoutsComponents = { BlankLayout, PanelLayout }

export default function App() {
	const [initialLoading, setInitialLoading] = useState(true)
	const defaultLayout = 'PanelLayout'
	const setRoute = useRouteStore((state) => state.setRoute)

	const endLoading = () => {
		setTimeout(() => {
			setInitialLoading(false)
		}, 750)
	}

	useEffect(() => {
		return api.intercept('request', (req) => {
			req.headers.language = 'es'
			const token = useAuthStore.getState().token
			if (token) {
				req.headers.Authorization = `Bearer ${token}`
			}
		})
	}, [])

	useEffect(() => {
		return api.intercept('error', async (res, req) => {
			if (res.status == 401) {
				if (window.location.pathname !== '/login') {
					window.sessionStorage.setItem('login_redirect', window.location.pathname)
					await useAuthStore.getState().logout()
					return false
				}
			}

			if (res.status == 403) {
				onForbidden()
				return false
			}

			if (res.error && req.toastError !== false) {
				toast.error(res.error)
			}
		})
	}, [])

	useEffect(() => {
		return api.intercept('success', (res, req) => {
			if (req.successMessage) {
				toast.success(req.successMessage)
			}
		})
	}, [])

	useEffect(() => {
		if (!useAuthStore.getState().token) {
			return endLoading()
		}

		setInitialLoading(true)
		useAuthStore
			.getState()
			.initializeUser()
			.then((user) => {
				if (user) endLoading()
			})
	}, [])

	if (initialLoading) {
		return <WasabilitoSpinner />
	}

	const ResolveRoutes = () => {
		return Object.entries(layoutsComponents).map(([layout, LayoutComponent], index) => {
			const layoutRoutes = routes.filter((route) => {
				return route.layout === layout || (!route.layout && defaultLayout === layout)
			})
			const routesPaths = layoutRoutes.map((route) => route.path)

			return (
				<Route
					path={routesPaths}
					exact
					key={index}
					render={(props) => {
						const currentPath = props.match.path
						const route = layoutRoutes.find((route) => route.path === currentPath)

						if (route.beforeRoute) {
							for (let fn of route.beforeRoute) {
								const redirectTo = fn()
								if (redirectTo) {
									if (redirectTo.startsWith('http')) {
										window.location.href = redirectTo
										return null
									} else {
										return <Redirect to={redirectTo} />
									}
								}
							}
						}

						setRoute(route)
						return <LayoutComponent route={route} />
					}}
				/>
			)
		})
	}

	return (
		<Suspense fallback={<WasabilitoSpinner />}>
			<AppRouter basename="">
				<Switch>
					{ResolveRoutes()}
					<Route path="/*" component={PageNotFoundView} />
				</Switch>
				<ScrollManager />
			</AppRouter>
		</Suspense>
	)
}

